import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { StyleSheet } from 'style-sheet-component';
import "./logoAnimated.scss"

interface Props {
    className?: string;
    white?: boolean;
}

export const LogoAnimated: FC<Props> = ({ className, white }) => {

    return (
        <div className={`Logo-Animated ${white ? 'Logo-Animated-white' : 'Logo-Animated-black'}`}>
        </div>
    );
}
