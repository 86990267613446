import React, { FC, useEffect } from 'react';
import { StyleSheet } from 'style-sheet-component';

interface Props {
    dimensions: { name: string, size: { cm: string, in: string } }[];
    onClose: () => void;
}
export const SizesScreen = ({ dimensions, onClose }: Props) => {

    useEffect(() => {
        document.body.style.backgroundColor = 'black';
    }, [])

    return (
        <div style={styles.container}>
            <p style={styles.unit}>cm</p>
            {dimensions.map(entry => <p style={styles.entry}>{entry.name.toUpperCase()} {entry.size.cm}</p>)}
            <div style={styles.back} onClick={onClose} className="noselect">← BACK</div>
        </div>
    );
}

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'black',
        overflow: 'hidden',
        top: 0,
        left: '100vw',
        fontFamily: 'Bangla MN',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        touchAction: 'none',
        msTouchAction: 'none'
    },
    back: {
        position: 'absolute',
        fontSize: '6vh',
        color: 'white',
        right: '50px',
        top: '100px',
        cursor: 'pointer'
    },
    entry: {
        fontSize: '25px',
        color: 'white',
        margin: '30px 0px 0px 40px',
        fontFamily: 'Bangla MN',
    },
    unit: {
        fontSize: 12,
        right: '30px',
        bottom: '90px',
        color: 'white',
        position: 'absolute',
        zIndex: 10,
        fontFamily: 'Bangla MN',
    }
})