import React, { FC, useState, useEffect } from 'react';
import './MenuIconComponentAnimated.scss';

interface Props {
    onOpen: (v: boolean) => void;
    setOpenOutside?: boolean;
    className?: string;
    id?: string;
    color: "white" | "black";
}

export const MenuIconAnimated: FC<Props> = ({ onOpen, color, setOpenOutside, id, className }) => {
    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        if (setOpenOutside !== undefined) {
            setOpen(setOpenOutside);
        }
    }, [setOpenOutside]);
    useEffect(() => {
        onOpen(isOpen);
    }, [isOpen, onOpen,]);

    return (
        <div id={id} onClick={() => setOpen(!isOpen)} className={`icon-container ${className || ''} ${isOpen ? 'open' : ''}`} >
            <span className={`color-${color}`}></span>
            <span className={`color-${color}`}></span>
            <span className={`color-${color}`}></span>
        </div>
    );
}