import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Logo } from 'src/components/LogoComponent';
import { MainMenu } from 'src/components/MainMenuComponent';
import { MenuIconAnimated } from 'src/components/MenuIconAnimatedComponent';
import { SocialSection } from 'src/components/SocialSectionCompontent';
import { uiSettings } from 'src/uiSettings';
import { SectionAcademy } from './SectionAcademy';
import "./academyStyle.scss";
import { MainAcademy } from './MainAcademy';
import { AboutUs } from './AboutUs';
import { Offer } from './Offer';
import { Conctact } from './Contact';
import { Schedule } from './Schedule';
import { Faq } from './Faq';
import { Offer2 } from './Offer2';
import { SectionAcademy15 } from './SectionAcademy15';

type ModelNameType = keyof typeof uiSettings;

interface Props {
    className?: string;

}

export const AcademyScreen: FC<Props> = ({ className }) => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const onClick = () => ref.current?.scrollIntoView({ behavior: 'smooth' });

    useEffect(() => {
        document.body.style.backgroundColor = isMenuOpen ? 'black' : 'white';

    }, [isMenuOpen])

    return (
        <div >
            <SocialSection ></SocialSection>
            <Logo to="/" size="small" color="black" className="App-Logo-position" />
            <MenuIconAnimated id="MenuIconAnimated" color={isMenuOpen ? 'white' : 'black'} setOpenOutside={isMenuOpen} onOpen={setMenuOpen} />
            <MainMenu
                isVisible={isMenuOpen}
                verticalItems={['WOMEN', 'COMMERCIAL', 'NEW FACES', 'ACADEMY']}
                horizontalItems={['ABOUT', 'JOIN', 'CONTACT']}
                onClick={() => setMenuOpen(false)}
            />

            {!isMenuOpen && <div className="academy-container">

                <SectionAcademy>
                    <MainAcademy></MainAcademy>
                </SectionAcademy>
                <SectionAcademy color="mustard">
                    <AboutUs></AboutUs>
                </SectionAcademy>
                <SectionAcademy15>
                    <Offer></Offer>
                </SectionAcademy15>
                {/* <SectionAcademy>
                    <Offer2></Offer2>
                </SectionAcademy> */}
                <SectionAcademy color="mustard">
                    <Schedule onClick={onClick}></Schedule>
                </SectionAcademy>
                <SectionAcademy>
                    <Faq></Faq>
                </SectionAcademy>
                <SectionAcademy color="mustard">
                    <Conctact ref={ref}></Conctact>
                </SectionAcademy>

            </div>}

        </div>
    );
}


// import React, { FC, useEffect, useState } from 'react';
// import "./academyStyle.scss";
// import { HeaderComponent } from './components/HeaderComponent';

// interface Props {

// }

// export const AcademyScreen: FC<Props> = () => {
//     return <>
//         <p className='academy-container'>
//             <HeaderComponent></HeaderComponent>
//         </p>
//     </>
// }
