import React, { FC } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Logo } from './LogoComponent';
import { StyleSheet } from 'style-sheet-component'

interface Props {
    className?: string;
    verticalItems: string[];
    horizontalItems: string[];
    onClick: (i: string) => void;
    isVisible: boolean;
}

export const MainMenu: FC<Props> = ({ className, horizontalItems, verticalItems, onClick, isVisible }) => {
    return (
        <AnimatePresence>
            {isVisible && <motion.div
                animate={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: -100 }}
                exit={{ opacity: 0, y: 100 }}
                transition={{ duration: 0.25, delay: isVisible ? 0.25 : 0 }}
                className={`MainMenu ${className || ''} `}>
                <Logo to="/" size="small" color="white" className="App-Logo-position"></Logo>
                <div>
                    <ul>
                        {verticalItems.map((item, key) => (
                            <li key={key} onClick={() => onClick(item)}>
                                <Link to={`/${item.toLowerCase()}`}>{item}</Link>
                            </li>
                        ))}
                    </ul>
                    <ul className="horizontal">
                        {horizontalItems.map((item, key) => (
                            <li key={key} onClick={() => onClick(item)}>
                                <Link to={`/${item.toLowerCase()}`}>{item}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </motion.div>
            }
        </AnimatePresence>
    );
}

const styles = StyleSheet.create({
    logoPosition: {
        position: 'absolute',
        left: '20px',
        top: '20px'
    }
})