import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { StyleSheet } from 'style-sheet-component';
import { ReactComponent as LogoSVG } from "../assets/svgs/REMODELS_MGMT.svg";
import { ReactComponent as LogoSVG_Black } from "../assets/svgs/REMODELS_MGMT_black.svg";

interface Props {
    className?: string;
    size: 'small' | 'big'
    color: 'black' | 'white'
    to: string;
    style?: any;
}

export const Logo: FC<Props> = ({ className, size, color, to, style }) => {

    return (
        <Link to={to}>
            <div style={style}>
                {color === 'black' ? <LogoSVG_Black className={className} style={size === 'big' ? styles.logoBig : styles.logoSmall} /> :
                    <LogoSVG className={className} style={size === 'big' ? styles.logoBig : styles.logoSmall} />}
            </div>
            {/* {size === 'big' ? <LogoSVG style={style.logoBig} /> : <div className={`App-Logo ${className || ''}`}>
                <div className={`App-logo-corner-top-left--${size} line-color-top-left--${color}-${size}`}></div>
                <div className={`App-logo-corner-bottom-right--${size} line-color-bottom-right--${color}-${size}`}></div>
                <p className={`App-logo-text--${size} font-color-${color}`}>RE MODELS</p>
                <p className={`App-logo-subtext--${size} font-color-${color}`}>MANAGEMENT</p>

            </div>} */}
        </Link>
    );
}

const styles = StyleSheet.create({
    logoBig: {
        width: '80vw'
    },
    logoSmall: {
        width: '120px',
        height: '50px'
    }
})