import React, { FC, useEffect, useState } from 'react';
import './formInput.css';

interface Props {
    className?: string;
    description: string;
    name: string;
    onSelect: (p: { [x: string]: string }) => void;
    validate?: boolean;
    checkValid: boolean;
}

export const FormInput: FC<Props> = ({ className, description, name, onSelect, validate = true, checkValid }) => {
    const [value, setValue] = useState('');
    const validator = (value: string) => {
        return value;
    }
    const [isError, setError] = useState(false);

    const onBlur = (value: string) => {
        if (!value.length) {
            onSelect({ [name]: '' });
            setError(true);
        } else {
            onSelect({ [name]: value });
            setError(false);
        }
    }

    useEffect(() => {
        if (checkValid) {
            onBlur(value);
        }
    }, [checkValid])

    return (
        <div className={`form-input-formInput ${className || ''}`}>
            <p className="form-input-description">{description}</p>
            <input name={name}
                onBlur={v => onBlur(v.target.value)}
                value={value} onChange={a => setValue(validator(a.target.value))}
                className="form-input-input"
                placeholder={`Type ${description.toLowerCase()} `}
                type="text">

            </input>
            {validate && isError && <p className="form-input-inputError">The field above is not filled!</p>}
        </div>
    );
}
