import React, { FC, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { LogoAnimated } from 'src/components/LogoAnimated/LogoAnimatedComponent';
import { BackButton } from '../components/BackButtonComponent';
import { Logo } from '../components/LogoComponent';
import { MainMenu } from '../components/MainMenuComponent';
import { MenuIconAnimated } from '../components/MenuIconAnimatedComponent';
import { ModelMainPageMenu } from '../components/ModelMainPageMenuComponent';
import { uiSettings } from '../uiSettings';

interface Props {
    className?: string;
}

export const ModelMainPageScreen: FC<Props> = ({ className }) => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const { params } = useRouteMatch<{ model: string }>();
    const { model } = params;
    const [background, setBackground] = useState<any>(null);
    // const history = useHistory();
    // const [loader, setLoader] = useState(true);

    useEffect(() => {

        const getBackground = async (name: string) => {
            const photo = await import(`../assets/images/${name}/background.jpg`);
            const img = new Image();
            img.src = photo.default;
            img.onload = () => {
                setBackground(photo.default);
            };
            return photo;
        }
        if (params && model) {
            if (uiSettings[model]) getBackground(model);
            else {
                // console.log(uiSettings[model])
            }
        }
        // else {
        //     history.push('/notfound');
        // }
    }, [params]);

    const [noDigitals, setNoDigitals] = useState(false);
    useEffect(() => {
        if (background) {

            if (uiSettings[model].carousel.digitals > 0) {
                const firstDigital = import(`../assets/images/${model}/digitals/1.jpg`);
                firstDigital.then(src => {
                    const img = new Image();
                    img.src = src.default;
                });
            } else { setNoDigitals(true) }

            const firstPortfolio = import(`../assets/images/${model}/portfolio/1.jpg`);
            firstPortfolio.then(src => {
                const img = new Image();
                img.src = src.default;
            });
        }
    }, [background]);

    return (
        <div className={`App-ModelMainPageScreen ${className || ''}`}>
            {/* {loader && <div className="loader"></div>} */}
            <MainMenu
                isVisible={isMenuOpen}

                verticalItems={['WOMEN', 'COMMERCIAL', 'NEW FACES', 'ACADEMY']}
                horizontalItems={['ABOUT', 'JOIN', 'CONTACT']}
                onClick={() => { }}
            />
            <Logo to="/" size="small" color="black" className="App-Logo-position" />
            <MenuIconAnimated className="onTop" id="MenuIconAnimated" color={isMenuOpen ? 'white' : 'black'} onOpen={setMenuOpen} />
            <p className="App-ModelTitle">{model.toUpperCase().split('_').join(' ')}</p>
            {!background &&
                <div className="loader-wrapper">
                    <LogoAnimated></LogoAnimated>
                    <p>Loading content...</p>
                </div>
            }
            {background && <>
                <img src={background}></img>
                <div className="App-ModelMainPage-text-section">
                    <ModelMainPageMenu noDigitals={noDigitals} instagram={uiSettings[model].instagram} items={['PORTFOLIO', 'DIGITALS']}></ModelMainPageMenu>
                </div>
            </>
            }
            {/* <BackButton></BackButton> */}
        </div>

    );
}

