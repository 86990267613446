import React, { FC } from 'react';
import { StyleSheet } from 'style-sheet-component';
import './buttonSendMail.css';
interface Props {
    className?: string;
    onClick: () => void;
    valid: boolean;
}

export const ButtonSendMail: FC<Props> = ({ className, onClick, valid = false }) => {

    return (
        <div onClick={onClick} className={`App-ButtonSendMail ${className || ''}`}>
            <p className="noselect" style={valid ? styles.valid : styles.notValid}>SEND</p>
        </div>
    );
}

const styles = StyleSheet.create({
    valid: {
        color: 'black'
    },
    notValid: {
        color: 'grey'
    }
})
