import React, { FC, useEffect, useRef, useState } from 'react';
import { Logo } from 'src/components/LogoComponent';
import { MainMenu } from 'src/components/MainMenuComponent';
import { MenuIconAnimated } from 'src/components/MenuIconAnimatedComponent';
import { ReactComponent as MailSvg } from '../../assets/svgs/mail.svg'
import { ReactComponent as PhoneSvg } from '../../assets/svgs/phone.svg'
import { ReactComponent as FbSvg } from '../../assets/svgs/facebook.svg'
import { ReactComponent as IgSvg } from '../../assets/svgs/instagram_white.svg'
import './contact.css'

interface Props { }

export const ContactScreen: FC<Props> = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isOpenOutside, setOpenOutside] = useState(false);
    // const video = useRef<HTMLVideoElement>(null);
    const setMenuOpen_ = (open: boolean) => {
        if (open === false && isMenuOpen) {
            setOpenOutside(open);
        }
        setMenuOpen(open);
    }

    useEffect(() => {
        document.body.style.backgroundColor = 'white';
    }, []);

    // useEffect(() => {
    //     const videoCurrent = video?.current;
    //     if (videoCurrent) {
    //         videoCurrent.playbackRate = 1;
    //         videoCurrent.play();
    //     }
    // }, [video]);

    return (
        <div className="page-about" >
            <Logo to="/" size="small" color="white" className="App-Logo-position" ></Logo>
            <MenuIconAnimated id="MenuIconAnimated" color={"white"} setOpenOutside={isOpenOutside} onOpen={setMenuOpen_} />
            <MainMenu
                isVisible={isMenuOpen}
                verticalItems={['WOMEN', 'COMMERCIAL', 'NEW FACES', 'ACADEMY']}
                horizontalItems={['ABOUT', 'JOIN', 'CONTACT']}
                onClick={() => setMenuOpen(false)}
            />
            <div className="contact-page-wrapper">
                <div>
                    <p className="contact-title">CONTACT</p>
                    <div className="contact-text-wrapper" >
                        <div className="contact-text2">

                            <p className="contact-text" ><MailSvg className="contact-text-svg" />
                                <a href="mailto:remodels@remodels-mgmt.com">
                                    REMODELS@REMODELS-MGMT.COM
                                </a>
                            </p>
                            <p className="contact-text"> <PhoneSvg className="contact-text-svg" />
                                +48 728 897 899
                            </p>
                            <p className="contact-text">
                                <a className="pointer" target="_blank" href={'https://www.instagram.com/remodels_mgmt'}>
                                    <IgSvg className="contact-text-svg" width={24} />
                                    INSTAGRAM
                                </a>
                            </p>
                            <p className="contact-text">
                                <a className="pointer" target="_blank" href={'https://facebook.com/remodelsmgmt'}>
                                    <FbSvg className="contact-text-svg" style={{ marginRight: '15px', marginLeft: '4' }} width={15} />
                                    FACEBOOK
                                </a>
                            </p>
                            <p className="contact-text" style={{ marginTop: '30px', marginLeft: '30px' }}>
                                AGENCY BASED IN WROCLAW
                            </p>
                        </div>
                        <video className="contact-video" playsInline={true} autoPlay={true} muted={true} loop={true} src={require('../../assets/movies/contact_medium.mp4')} />
                    </div>
                </div>
            </div>
        </div>
    );
}
// www.facebook.com/remodelsmgmt www.instagram.com/remodels_mgmt
// const styles = StyleSheet.create({
//     video: {
//         position: 'fixed',
//         right: 0,
//         bottom: 0,
//         minWidth: '100vw',
//         minHeight: '100vh',
//         zIndex: -1,
//     },
//     about: {
//         color: 'white',
//         fontFamily: 'Bangla MN',
//         fontSize: 'calc(2vw + 20px)',
//         marginBottom: '75vh',
//         textAlign: 'center',
//         // marginTop: '50px',
//         position: 'absolute',
//         top: '5vh',
//         left: '50%',
//         transform: 'translateX(-50%)'


//         // fontWeight: 'bold'
//     },
//     pageWrapper: {
//         // display: 'flex',
//         // justifyContent: 'center',
//         // alignItems: 'center',
//         // height: '80vh'
//     },
//     textWrapper: {
//         display: 'flex',
//         justifyContent: 'flex-start',
//         alignItems: 'center',
//         position: 'absolute',
//         bottom: '5vh',
//         width: '100%',
//     },
//     text2: {
//         color: 'white',
//         // textAlign: 'center',
//         fontFamily: 'Bangla MN',
//         // fontSize: '16px',
//         backgroundColor: 'rgba(0,0,0,0.5)',
//         padding: '35px',
//         marginLeft: '10vw',
//         marginRight: '5vw',
//     },
//     text: {

//         fontSize: '1rem',
//         lineHeight: '20px',

//         fontWeight: 'bold',
//         textAlign: 'left',
//         cursor: 'pointer',
//         paddingBottom: '10px'
//     }
// })