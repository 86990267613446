import React, { FC } from 'react';
import { ReactComponent as InstagramIcon } from '../assets/svgs/instagram.svg'
import { ReactComponent as FacebookIcon } from '../assets/svgs/facebook.svg'

interface Props {
    className?: string;
}

export const SocialSection: FC<Props> = ({ className }) => {

    return (
        <div className={`App-SocialSection ${className || ''}`}>
            <a target="_blank" href={'https://www.instagram.com/remodels_mgmt'}>
                <InstagramIcon width={30} className="instagram" />
            </a>
            <a target="_blank" href={'https://facebook.com/remodelsmgmt'}>
                <FacebookIcon width={30} className="facebook" />
            </a>
        </div>
    );
}
// 'https://facebook.com/remodelsmgmt', 'fb://remodelsmgmt'

// 'https://www.instagram.com/remodels_mgmt/' 'instagram://remodels_mgmt';