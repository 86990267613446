import React, { CSSProperties, FC, useState } from 'react';

interface Props {
    currentIndex: number;
    picturesLength: number;
    style?: CSSProperties;
}

export const PictureCounter: FC<Props> = ({ currentIndex, picturesLength, style }: Props) => {

    return (
        <p style={style} className="App-Counter noselect">
            {`${currentIndex + 1}/${picturesLength}`}
        </p>
    );
}
