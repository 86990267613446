import React, { useEffect, useState } from "react";

interface MinWidthPx {
    min: number;
    max?: null;
}
interface MaxWidthPx {
    max: number;
    min?: null;
}

export const useMediaQuery = (query: MinWidthPx | MaxWidthPx) => {
    const mediaMatch = window.matchMedia(`(${query.min ? 'min' : 'max'}-width: ${query.min || query.max}px)`);
    const [matches, setMatches] = useState(mediaMatch.matches);

    useEffect(() => {
        const handler = (e: any) => setMatches(e.matches);
        mediaMatch.addListener(handler);
        return () => mediaMatch.removeListener(handler);
    });
    return matches;
}
