import React, { FC, useEffect, useState } from 'react';
import { AppLogoText } from '../components/AppLogoTextComponent';
import { MainMenu } from '../components/MainMenuComponent';
import { MenuIconAnimated } from '../components/MenuIconAnimatedComponent';
import { uiSettings } from '../uiSettings';

interface Props { }

export const EntryScreen: FC<Props> = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isOpenOutside, setOpenOutside] = useState(false);
    const setMenuOpen_ = (open: boolean) => {
        if (open === false && isMenuOpen) {
            setOpenOutside(open);
        }
        setMenuOpen(open);
    }
    useEffect(() => {
        document.body.style.backgroundColor = 'black';
    }, []);
    return (
        <div className="App" onClick={() => setOpenOutside(true)}>
            <MenuIconAnimated id="MenuIconAnimated" color="white" setOpenOutside={isOpenOutside} onOpen={setMenuOpen_} />
            <MainMenu
                isVisible={isMenuOpen}
                verticalItems={['WOMEN', 'COMMERCIAL', 'NEW FACES', 'ACADEMY']}
                horizontalItems={['ABOUT', 'JOIN', 'CONTACT']}
                onClick={() => setMenuOpen(false)}
            />
            <AppLogoText isVisible={!isMenuOpen} />
        </div>
    );
}