
import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as PaintMustartSvg } from "../../assets/academy/svgs/ciapka1.svg";
interface Props {
    className?: string;
    top?: string;
    left?: string;
    bottom?: string;
}

export const StarBadge: FC<Props> = ({ top, left, bottom }) => {
    return (
        <div className="offer-star" style={{ top, left, bottom }}>
            <div className='offer-star-contaier'>
                <PaintMustartSvg width={200} height={100} className='offer-paint' />
                <p>Gwiazda spotkania <br></br>
                    Ania Piszczałka</p>
            </div>
        </div>

    )
}