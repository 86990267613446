import React, { useEffect } from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { EntryScreen } from './screens/EntryScreen';
import { GalleryScreen } from './screens/GalleryScreen';
import { ModelDetailsScreen } from './screens/ModelDetailsScreen';
import { ModelMainPageScreen } from './screens/ModelMainPageScreen';
import { NotFoundScreen } from './screens/NotFoundScreen';
import { uiSettings } from './uiSettings';
import { JoinScreen } from './screens/joinScreen/JoinScreen';
import { AboutScreen } from './screens/aboutScreen/AboutScreen';
import { ContactScreen } from './screens/contactScreen/ContactScreen';
import { useState } from 'react';
import { AcademyScreen } from './screens/academyScreen/AcademyScreen';

type ModelNameType = keyof typeof uiSettings;

function App() {
  const [models, setModels] = useState<string[]>([]);
  const [commercial, setCommercial] = useState<string[]>([]);
  const [newFaces, setNewFaces] = useState<string[]>([]);
  useEffect(() => {
    const modelNames = Object.keys(uiSettings);
    setModels(modelNames);
    const commercial = modelNames.filter(name => uiSettings[name].commercial);
    setCommercial(commercial);
    const newFaces = modelNames.filter(name => uiSettings[name].newFace);
    setNewFaces(newFaces);

    modelNames.map(k => {
      const img = new Image();
      img.src = uiSettings[k].profileImage;
    });
  }, [])

  return (
    <Router>

      <div className="App-router" >
        <Switch>
          <Route path="/women/:model/portfolio">
            <ModelDetailsScreen type="portfolio" />
          </Route>
          <Route path="/women/:model/digitals">
            <ModelDetailsScreen type="digitals" />
          </Route>
          <Route path="/women/:model">
            <ModelMainPageScreen />
          </Route>
          <Route path="/women">
            <GalleryScreen models={models.sort() as ModelNameType[]}></GalleryScreen>
          </Route>
          <Route path="/commercial/:model/portfolio">
            <ModelDetailsScreen type="portfolio" />
          </Route>
          <Route path="/commercial/:model/digitals">
            <ModelDetailsScreen type="digitals" />
          </Route>
          <Route path="/commercial/:model">
            <ModelMainPageScreen />
          </Route>
          <Route path="/commercial">
            <GalleryScreen models={commercial.sort() as ModelNameType[]}></GalleryScreen>
          </Route>
          <Route path="/new faces/:model/portfolio">
            <ModelDetailsScreen type="portfolio" />
          </Route>
          <Route path="/new faces/:model/digitals">
            <ModelDetailsScreen type="digitals" />
          </Route>
          <Route path="/new faces/:model">
            <ModelMainPageScreen />
          </Route>
          <Route path="/new faces">
            <GalleryScreen models={newFaces.sort() as ModelNameType[]}></GalleryScreen>
          </Route>

          <Route path="/join">
            <JoinScreen />
          </Route>

          <Route path="/academy">
            <AcademyScreen />
          </Route>

          <Route path="/about">
            <AboutScreen />
          </Route>

          <Route path="/contact">
            <ContactScreen />
          </Route>

          <Route path="/notfound">
            <NotFoundScreen></NotFoundScreen>
          </Route>

          <Route path="/" >
            <EntryScreen></EntryScreen>
          </Route>

        </Switch>
      </div >
    </Router>
  );
}

export default App;
