import React, { FC, useEffect, useState } from 'react';

interface Props {
    className?: string;
}

const photo1 = require("../../assets/academy/images/TESTS1.jpg");
const photo2 = require("../../assets/academy/images/TESTS2.jpg");
const photo3 = require("../../assets/academy/images/TESTS3.jpg");
const photo4 = require("../../assets/academy/images/TESTS4.jpg");
const photo5 = require("../../assets/academy/images/TESTS5.jpg");
export const AboutUs: FC<Props> = ({ }) => {
    return (
        <div className={`aboutus-container font-md`}>
            <p>Projekt Modeling to szkoła modelek założona przez Nikolę Dudar emerytowaną modelkę i właścicielkę agencji modelek Remodels Management, która niezmiennie cieszy się dobrą opinią w kraju i za granicą.
                Szkoła modelek oferuje aspirującym modelkom dwa rodzaje kursów: kurs weekendowy, czyli intensywną formę warsztatów, które dają uczestniczkom możliwość poznania świata modelingu od podszewki, udział w sesji zdjęciowej, mini pokazie mody oraz w innych praktycznych zajęciach, oraz pojedyncze warsztaty tematyczne. Uczestniczki prowadzone pod okiem profesjonalistów z branży będą miały okazję odkryć swoje naturalne predyspozycje, wzmocnić mocne strony swojej osobowości, a przede wszystkim przeżyć świetną przygodę.
            </p>
            <div className='photos-container'>
                <img src={photo1}></img>
                <img src={photo2}></img>
                <img src={photo3}></img>
                <img src={photo4}></img>
                <img src={photo5}></img>
            </div>
        </div>

    );
}