import React, { FC, useEffect, useState, Ref, forwardRef } from 'react';
import { ReactComponent as MailSvg } from '../../assets/svgs/mail.svg'
import { ReactComponent as PhoneSvg } from '../../assets/svgs/phone.svg'

interface Props {
    className?: string;
}

const contactInfo = require("../../assets/academy/images/contactInfo.png");
const contactLogo = require("../../assets/academy/images/contactLogo.png");

export const Conctact = forwardRef(({ }, ref: any) => {
    return (
        <div ref={ref} className={`contact-container`}>
            <img className='svg-rwd' style={{ marginBottom: '50px' }} src={contactLogo}></img>
            <div>
                <p>
                    <MailSvg className="contact-text-svg" style={{ fill: 'black' }} />
                    <a href="mailto: remodels@remodels-mgmt.com">remodels@remodels-mgmt.com</a>
                </p>
                <p>
                    <PhoneSvg className="contact-text-svg" style={{ fill: 'black' }} />
                    +48 571 082 027
                </p>
                <p style={{ fontWeight: 600, marginTop: '20px' }}>KURSY ODBYWAJĄ SIĘ WE WROCŁAWIU</p>
            </div>
            <img className='svg-rwd' style={{ maxWidth: '500px', marginTop: '50px' }} src={contactInfo}></img>
        </div>

    );
})
