import React, { FC } from 'react';
import { motion, AnimatePresence } from "framer-motion"
import { Logo } from './LogoComponent';

interface Props {
    className?: string;
    isVisible: boolean;
}

export const AppLogoText: FC<Props> = ({ className, isVisible }) => {
    return (
        <AnimatePresence>
            {isVisible && <motion.div
                className={`App-Logo-Text ${className || ''}`}
                animate={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: -100 }}
                exit={{ opacity: 0, y: 100 }}
                transition={{ duration: 0.25, delay: isVisible ? 0.25 : 0 }}
            >
                <Logo to="/" color="white" size="big"></Logo>
            </motion.div>}
        </AnimatePresence>
    );
}