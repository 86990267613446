import React, { FC, useEffect, useState } from 'react';
import { useMediaQuery } from 'src/hooks/mediaQueryHook';
import { StarBadge } from './StarBadge';

interface Props {
    className?: string;
}

const photo1 = require("../../assets/academy/images/1.jpg");
const photo2 = require("../../assets/academy/images/5.jpg");
const photo3 = require("../../assets/academy/images/6.jpg");


export const Offer: FC<Props> = ({ }) => {
    const matches = useMediaQuery({ min: 750 });
    return (
        <div className={`offer-container font-md`}>
            <div className='offer-container-inner'>
                <div className='offer-container-inner-list'>
                    <p style={{ fontWeight: 600, marginBottom: '20px' }}>Kurs weekendowy (kompleksowy):</p>

                    <p>Zajęcia aktorskie, ruchu scenicznego, autoprezentacji,</p>
                    <p>Naukę chodzenia wybiegowego, chodzenia na obcasach,</p>
                    <p>Pozowanie przed obiektywem,</p>
                    <p>Lekcję makijażu oraz pielęgnacji twarzy i ciała,</p>
                    <p>Podstawy stylizacji,</p>
                    <p>Trening modelek,</p>
                    <p>Wykład z dietetykiem i pomiar ciała,</p>
                    <p>Podstawy modelingu oraz praw i obowiązków,</p>
                    <p>Spotkanie z gwiazdą TOP Model Anną Piszczałką,</p>
                    <p>Indywidualna sesja zdjęciowa,</p>
                    <p>Pokaz finałowy</p>

                    <p style={{ marginTop: '30px', fontWeight: 600, }}>Cena 2,400 zł</p>
                    {/* <p style={{ fontSize: '15px' }}> NIE MA OGRANICZEŃ WZROSTU, WAGI ANI WIEKU!</p> */}
                    <p style={{ marginTop: '10px', fontSize: '15px' }}>*Na kandydatki czekać będą upominki, bufet wytrawny/słodki z kawą, herbatą i napojami.</p>
                    <p style={{ marginTop: '10px', fontSize: '15px' }}>*Czas trwania 2 dni.</p>

                </div>
                <div className={`offer-container-inner-list`}>
                    <p style={{ fontWeight: 600, marginBottom: '20px' }}>Warsztaty catwalk:</p>
                    <p>Nauka odpowiedniej techniki podstawowego chodu, który zastosować można na castingach i wybiegach.</p>
                    <p>Warsztat pomoże Ci poprawić posturę, opanować równowagę, zdobyć pewność siebie na szpilkach, którą wykorzystasz w życiu prywatnym i/lub zawodowym.</p>
                    <p style={{ marginTop: '30px', fontWeight: 600, }}>Cena: 500 zł</p>
                    <p style={{ marginTop: '10px', fontSize: '15px' }}>*Czas trwania 2 dni.</p>
                </div>
                <div className={`offer-container-inner-list`}>
                    <p style={{ fontWeight: 600, marginBottom: '20px' }}>Warsztaty pozowania:</p>
                    <p>Chcesz wiedzieć, jak wykonywać pozy, które widzisz w magazynach o modzie? Na warsztatach dowiedz się, jak je osiągnąć. Zajęcia obejmują podstawowe pozy fashion, które można wykorzystać na testach, polaroidach i na rynkach Europejskich,  podstawowe rodzaje póz. </p>

                    <p style={{ marginTop: '30px', fontWeight: 600, }}>Cena: 400 zł</p>
                    <p style={{ marginTop: '10px', fontSize: '15px' }}>*Czas trwania 1 dzień.</p>
                </div>
            </div>
            {matches && <div className='photos-container'>
                <img src={photo1}></img>
                <StarBadge top='300px'></StarBadge>
                <img src={photo2}></img>
                {/* <StarBadge bottom='400px'></StarBadge> */}
                <img src={photo3}></img>
                {/* <StarBadge bottom='40px'></StarBadge> */}
            </div>}
            {!matches && <div className='photos-container-row'>
                <img src={photo1}></img>
                <img src={photo2}></img>
                <img src={photo3}></img>
                <StarBadge></StarBadge>
            </div>}
        </div>

    );
}


// {!matches && <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '10px' }}>
// {/* <img style={{ objectFit: 'contain', maxHeight: '40vw' }} src={photo1}></img> */}
// <img style={{ objectFit: 'contain', maxHeight: '40vw' }} src={photo2}></img>
// <img style={{ objectFit: 'contain', maxHeight: '40vw' }} src={photo3}></img>
// </div>}