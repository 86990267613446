import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as AcademyLogoSVG } from "../../assets/academy/svgs/logoEntry250.svg";

interface Props {
    className?: string;
}

export const MainAcademy: FC<Props> = ({ }) => {
    return (
        <div className={`main-container `}>
            <AcademyLogoSVG className='svg-rwd' />
            <p className='font-2md'>SZKOŁA MODELEK WROCŁAW</p>
        </div>
    );
}