import React, { CSSProperties, FC, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

interface Props {
    height?: number;
    width?: number
    text: string;
    image: any;

    style?: CSSProperties;
}

export const GalleryPicture: FC<Props> = ({ width = 170, text, image, style }) => {
    const match = useRouteMatch();

    return (
        <div style={style} className="App-gallery-picture">
            <Link to={match.url + '/' + text.toLowerCase().split(' ').join('_')}>
                <img width={width} height={1.3 * width} src={image}></img>
            </Link>
            <p className="App-gallery-text">{text}</p>
        </div>
    );
}
