export const uiSettings = {

    lexi: {
        name: "Lexi",
        profileImage: require("./assets/images/lexi/main.jpg"),
        instagram: 'https://www.instagram.com/ola.ptaszynska/',
        carousel: {
            digitals: 10,
            portfolio: 54
        },
        newFace: false,
        commercial: false,
        dimensions: [
            {
                name: "height",
                size: {
                    cm: "175"
                }
            },
            {
                name: "bust",
                size: {
                    cm: "82"
                }
            },
            {
                name: "waist",
                size: {
                    cm: "62"
                }
            },
            {
                name: "hips",
                size: {
                    cm: "90"
                }
            },
            {
                name: "shoes",
                size: {
                    cm: "40"
                }
            },
            {
                name: "hair",
                size: {
                    cm: "BLONDE"
                }
            },
            {
                name: "eyes",
                size: {
                    cm: "GREEN"
                }
            }
        ]
    },
    klaudia: {
        name: "Klaudia",
        profileImage: require("./assets/images/klaudia/main.jpg"),
        instagram: 'https://www.instagram.com/klaudiaxdzia/',
        carousel: {
            digitals: 10,
            portfolio: 25
        },
        newFace: false,
        commercial: true,
        dimensions: [
            {
                name: "height",
                size: {
                    cm: "167"
                }
            },
            {
                name: "bust",
                size: {
                    cm: "79"
                }
            },
            {
                name: "waist",
                size: {
                    cm: "61"
                }
            },
            {
                name: "hips",
                size: {
                    cm: "89"
                }
            },
            {
                name: "shoes",
                size: {
                    cm: "37"
                }
            },
            {
                name: "hair",
                size: {
                    in: "BLONDE",
                    cm: "BLONDE"
                }
            },
            {
                name: "eyes",
                size: {
                    cm: "BLUE"
                }
            }
        ]
    },
    ari: {
        name: "Ari",
        profileImage: require("./assets/images/ari/main.jpg"),
        instagram: 'https://www.instagram.com/ariadnasyska/',
        carousel: {
            digitals: 10,
            portfolio: 36
        },
        newFace: false,
        commercial: true,
        dimensions: [
            {
                name: "height",
                size: {
                    cm: "170"
                }
            },
            {
                name: "bust",
                size: {
                    cm: "80"
                }
            },
            {
                name: "waist",
                size: {
                    cm: "57"
                }
            },
            {
                name: "hips",
                size: {
                    cm: "86"
                }
            },
            {
                name: "shoes",
                size: {
                    cm: "37"
                }
            },
            {
                name: "hair",
                size: {
                    cm: "BROWN"
                }
            },
            {
                name: "eyes",
                size: {
                    cm: "BROWN"
                }
            }
        ]
    },
    rosa: {
        name: "Rosa",
        profileImage: require("./assets/images/rosa/main.jpg"),
        instagram: 'https://www.instagram.com/daffodil_pink/',
        carousel: {
            digitals: 10,
            portfolio: 53
        },
        newFace: false,
        commercial: false,
        dimensions: [
            {
                name: "height",
                size: {
                    cm: "174"
                }
            },
            {
                name: "bust",
                size: {
                    cm: "73"
                }
            },
            {
                name: "waist",
                size: {
                    cm: "58"
                }
            },
            {
                name: "hips",
                size: {
                    cm: "87"
                }
            },
            {
                name: "shoes",
                size: {
                    cm: "37"
                }
            },
            {
                name: "hair",
                size: {
                    cm: "BROWN"
                }
            },
            {
                name: "eyes",
                size: {
                    cm: "BLUE"
                }
            }
        ]
    },
    martyna: {
        name: "Martyna",
        profileImage: require("./assets/images/martyna/main.jpg"),
        instagram: 'https://www.instagram.com/theoneofviking/',
        carousel: {
            digitals: 10,
            portfolio: 19
        },
        newFace: true,
        commercial: false,
        dimensions: [
            {
                name: "height",
                size: {
                    cm: "180"
                }
            },
            {
                name: "bust",
                size: {
                    cm: "81"
                }
            },
            {
                name: "waist",
                size: {
                    cm: "59"
                }
            },
            {
                name: "hips",
                size: {
                    cm: "89"
                }
            },
            {
                name: "shoes",
                size: {
                    cm: "40"
                }
            },
            {
                name: "hair",
                size: {
                    cm: "BLONDE"
                }
            },
            {
                name: "eyes",
                size: {
                    cm: "BLUE"
                }
            }
        ]
    },
    hana: {
        name: "Hana",
        profileImage: require("./assets/images/hana/main.jpg"),
        instagram: 'https://www.instagram.com/haniadoskocz/',
        carousel: {
            digitals: 10,
            portfolio: 17
        },
        newFace: true,
        commercial: false,
        dimensions: [
            {
                name: "height",
                size: {
                    cm: "176"
                }
            },
            {
                name: "bust",
                size: {
                    cm: "86"
                }
            },
            {
                name: "waist",
                size: {
                    cm: "64"
                }
            },
            {
                name: "hips",
                size: {
                    cm: "88"
                }
            },
            {
                name: "shoes",
                size: {
                    cm: "40"
                }
            },
            {
                name: "hair",
                size: {
                    cm: "BROWN"
                }
            },
            {
                name: "eyes",
                size: {
                    cm: "BLUE"
                }
            }
        ]
    },
    paula: {
        name: "Paula",
        profileImage: require("./assets/images/paula/main.jpg"),
        instagram: 'https://www.instagram.com/paulapachucka/',
        carousel: {
            digitals: 0,
            portfolio: 15
        },
        newFace: true,
        commercial: true,
        dimensions: [
            {
                name: "height",
                size: {
                    cm: "173"
                }
            },
            {
                name: "bust",
                size: {
                    cm: "86"
                }
            },
            {
                name: "waist",
                size: {
                    cm: "68"
                }
            },
            {
                name: "hips",
                size: {
                    cm: "95"
                }
            },
            {
                name: "shoes",
                size: {
                    cm: "39,5"
                }
            },
            {
                name: "hair",
                size: {
                    cm: "BLONDE"
                }
            },
            {
                name: "eyes",
                size: {
                    cm: "GREEN"
                }
            }
        ]
    },
    danny: {
        name: "Danny",
        profileImage: require("./assets/images/danny/main.jpg"),
        instagram: 'https://www.instagram.com/hvitermeg/',
        carousel: {
            digitals: 0,
            portfolio: 35
        },
        newFace: true,
        commercial: true,
        dimensions: [
            {
                name: "height",
                size: {
                    cm: "167"
                }
            },
            {
                name: "bust",
                size: {
                    cm: "78"
                }
            },
            {
                name: "waist",
                size: {
                    cm: "55"
                }
            },
            {
                name: "hips",
                size: {
                    cm: "74"
                }
            },
            {
                name: "shoes",
                size: {
                    cm: "37"
                }
            },
            {
                name: "hair",
                size: {
                    cm: "BLONDE"
                }
            },
            {
                name: "eyes",
                size: {
                    cm: "GREEN"
                }
            }
        ]
    },
    aleksandra: {
        name: "Aleksandra",
        profileImage: require("./assets/images/aleksandra/main.jpg"),
        instagram: 'https://www.instagram.com/ola_krajewska97/',
        carousel: {
            digitals: 10,
            portfolio: 11
        },
        newFace: true,
        commercial: true,
        dimensions: [
            {
                name: "height",
                size: {
                    cm: "172"
                }
            },
            {
                name: "bust",
                size: {
                    cm: "85"
                }
            },
            {
                name: "waist",
                size: {
                    cm: "63"
                }
            },
            {
                name: "hips",
                size: {
                    cm: "91"
                }
            },
            {
                name: "shoes",
                size: {
                    cm: "36"
                }
            },
            {
                name: "hair",
                size: {
                    cm: "BROWN"
                }
            },
            {
                name: "eyes",
                size: {
                    cm: "BROWN"
                }
            }
        ]
    },
    magda: {
        name: "Magda",
        profileImage: require("./assets/images/magda/main.jpg"),
        instagram: 'https://www.instagram.com/magdalena.weg/',
        carousel: {
            digitals: 10,
            portfolio: 9
        },
        newFace: true,
        commercial: false,
        dimensions: [
            {
                name: "height",
                size: {
                    cm: "178"
                }
            },
            {
                name: "bust",
                size: {
                    cm: "81"
                }
            },
            {
                name: "waist",
                size: {
                    cm: "59"
                }
            },
            {
                name: "hips",
                size: {
                    cm: "89"
                }
            },
            {
                name: "shoes",
                size: {
                    cm: "40"
                }
            },
            {
                name: "hair",
                size: {
                    cm: "BLONDE"
                }
            },
            {
                name: "eyes",
                size: {
                    cm: "GREEN"
                }
            }
        ]
    },
    nastya: {
        name: "Nastya",
        profileImage: require("./assets/images/nastya/main.jpg"),
        instagram: 'https://www.instagram.com/nasikmikka/ ',
        carousel: {
            digitals: 10,
            portfolio: 37
        },
        newFace: false,
        commercial: false,
        dimensions: [
            {
                name: "height",
                size: {
                    cm: "174"
                }
            },
            {
                name: "bust",
                size: {
                    cm: "80"
                }
            },
            {
                name: "waist",
                size: {
                    cm: "60"
                }
            },
            {
                name: "hips",
                size: {
                    cm: "89"
                }
            },
            {
                name: "shoes",
                size: {
                    cm: "38"
                }
            },
            {
                name: "hair",
                size: {
                    cm: "BLONDE"
                }
            },
            {
                name: "eyes",
                size: {
                    cm: "BLUE"
                }
            }
        ]
    },
    yanka: {
        name: "Yanka",
        profileImage: require("./assets/images/yanka/main.jpg"),
        instagram: 'https://www.instagram.com/jankadabrowska/',
        carousel: {
            digitals: 10,
            portfolio: 39
        },
        newFace: false,
        commercial: false,
        dimensions: [
            {
                name: "height",
                size: {
                    cm: "174"
                }
            },
            {
                name: "bust",
                size: {
                    cm: "80"
                }
            },
            {
                name: "waist",
                size: {
                    cm: "62"
                }
            },
            {
                name: "hips",
                size: {
                    cm: "86"
                }
            },
            {
                name: "shoes",
                size: {
                    cm: "39"
                }
            },
            {
                name: "hair",
                size: {
                    cm: "BLONDE"
                }
            },
            {
                name: "eyes",
                size: {
                    cm: "BLUE"
                }
            }
        ]
    },
    'ania_p': {
        name: "Ania P",
        profileImage: require("./assets/images/ania_p/main.jpg"),
        instagram: 'https://www.instagram.com/aniapiszczalka/',
        carousel: {
            digitals: 0,
            portfolio: 31
        },
        newFace: false,
        commercial: false,
        dimensions: [
            {
                name: "height",
                size: {
                    cm: "178"
                }
            },
            {
                name: "bust",
                size: {
                    cm: "85"
                }
            },
            {
                name: "waist",
                size: {
                    cm: "63"
                }
            },
            {
                name: "hips",
                size: {
                    cm: "91"
                }
            },
            {
                name: "shoes",
                size: {
                    cm: "40"
                }
            },
            {
                name: "hair",
                size: {
                    cm: "BLONDE"
                }
            },
            {
                name: "eyes",
                size: {
                    cm: "BLUE"
                }
            }
        ]
    },
    'hania_k': {
        name: "Hania K",
        profileImage: require("./assets/images/hania_k/main.jpg"),
        instagram: 'https://www.instagram.com/hania.klimas/',
        carousel: {
            digitals: 0,
            portfolio: 13
        },
        newFace: true,
        commercial: false,
        dimensions: [
            {
                name: "height",
                size: {
                    cm: "184"
                }
            },
            {
                name: "bust",
                size: {
                    cm: "85"
                }
            },
            {
                name: "waist",
                size: {
                    cm: "63"
                }
            },
            {
                name: "hips",
                size: {
                    cm: "91"
                }
            },
            {
                name: "shoes",
                size: {
                    cm: "40"
                }
            },
            {
                name: "hair",
                size: {
                    cm: "BLONDE"
                }
            },
            {
                name: "eyes",
                size: {
                    cm: "BLUE"
                }
            }
        ]
    },
    'miranda': {
        name: "Miranda",
        profileImage: require("./assets/images/miranda/main.jpg"),
        instagram: 'https://www.instagram.com/adamczykmiranda/',
        carousel: {
            digitals: 0,
            portfolio: 4
        },
        newFace: true,
        commercial: false,
        dimensions: [
            {
                name: "height",
                size: {
                    cm: "178"
                }
            },
            {
                name: "bust",
                size: {
                    cm: "88"
                }
            },
            {
                name: "waist",
                size: {
                    cm: "63"
                }
            },
            {
                name: "hips",
                size: {
                    cm: "91"
                }
            },
            {
                name: "shoes",
                size: {
                    cm: "40"
                }
            },
            {
                name: "hair",
                size: {
                    cm: "BLONDE"
                }
            },
            {
                name: "eyes",
                size: {
                    cm: "BLUE"
                }
            }
        ]
    },
    'paulina_g': {
        name: "Paulina G",
        profileImage: require("./assets/images/paulina_g/main.jpg"),
        instagram: 'https://www.instagram.com/pualina.0v0/',
        carousel: {
            digitals: 0,
            portfolio: 13
        },
        newFace: true,
        commercial: false,
        dimensions: [
            {
                name: "height",
                size: {
                    cm: "173"
                }
            },
            {
                name: "bust",
                size: {
                    cm: "81"
                }
            },
            {
                name: "waist",
                size: {
                    cm: "62"
                }
            },
            {
                name: "hips",
                size: {
                    cm: "89"
                }
            },
            {
                name: "shoes",
                size: {
                    cm: "38"
                }
            },
            {
                name: "hair",
                size: {
                    cm: "BLONDE"
                }
            },
            {
                name: "eyes",
                size: {
                    cm: "BLUE"
                }
            }
        ]
    },
    'oliwia': {
        name: "Oliwia",
        profileImage: require("./assets/images/oliwia/main.jpg"),
        instagram: 'https://www.instagram.com/the.olivetheory/',
        carousel: {
            digitals: 0,
            portfolio: 10
        },
        newFace: true,
        commercial: false,
        dimensions: [
            {
                name: "height",
                size: {
                    cm: "177"
                }
            },
            {
                name: "bust",
                size: {
                    cm: "83"
                }
            },
            {
                name: "waist",
                size: {
                    cm: "66"
                }
            },
            {
                name: "hips",
                size: {
                    cm: "94"
                }
            },
            {
                name: "shoes",
                size: {
                    cm: "39"
                }
            },
            {
                name: "hair",
                size: {
                    cm: "BROWN"
                }
            },
            {
                name: "eyes",
                size: {
                    cm: "GREEN"
                }
            }
        ]
    },
} as const;
