import React, { FC, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

interface Props {
    className?: string;
    items: string[];
    instagram: string;
    noDigitals?: boolean;
}

export const ModelMainPageMenu: FC<Props> = ({ className, items, instagram, noDigitals }) => {
    const [selected, setSelected] = useState('');
    const match = useRouteMatch();

    return (
        <div className={`App-ModelMainPageMenu ${className || ''}`}>
            {items.map((item, key) => (
                <div key={key} >
                    <Link to={noDigitals && item === 'DIGITALS' ? match.url : match.url + '/' + item.toLowerCase()}>
                        <p className={`${noDigitals && item === 'DIGITALS' ? 'disabled' : ''}`} onClick={() => setSelected(item)}>{item}</p>
                        <div className={`underline ${selected === item ? 'active' : ''}`}></div>
                    </Link>

                </div>
            ))}

            <div  >
                <a href={instagram} target="_blank">
                    <p onClick={() => setSelected('instagram')}>INSTAGRAM</p>
                    <div className={`underline ${selected === 'instagram' ? 'active' : ''}`}></div>
                </a>
            </div>

        </div>
    );
}

