import React, { FC, useEffect, useState } from 'react';
import { GalleryPicture } from '../components/GalleryPictureComponent';
import { Logo } from '../components/LogoComponent';
import { MainMenu } from '../components/MainMenuComponent';
import { MenuIconAnimated } from '../components/MenuIconAnimatedComponent';
import { SocialSection } from '../components/SocialSectionCompontent';
import { uiSettings } from '../uiSettings';

type ModelNameType = keyof typeof uiSettings;

interface Props {
    className?: string;
    models: ModelNameType[];
}

export const GalleryScreen: FC<Props> = ({ className, models }) => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [pictures, setPictures] = useState<{ name: string, image: any }>();

    // const setMenuOpenWithBackground = (value: boolean) => {
    //     setMenuOpen(value);
    // }

    useEffect(() => {
        document.body.style.backgroundColor = isMenuOpen ? 'black' : 'white';

    }, [isMenuOpen])

    return (
        <div className={`App-gallery-screen ${className || ''}`}>
            <SocialSection ></SocialSection>
            <Logo to="/" size="small" color="black" className="App-Logo-position" />
            <MenuIconAnimated id="MenuIconAnimated" color={isMenuOpen ? 'white' : 'black'} setOpenOutside={isMenuOpen} onOpen={setMenuOpen} />
            <MainMenu
                isVisible={isMenuOpen}
                verticalItems={['WOMEN', 'COMMERCIAL', 'NEW FACES', 'ACADEMY']}
                horizontalItems={['ABOUT', 'JOIN', 'CONTACT']}
                onClick={() => setMenuOpen(false)}
            />

            {!isMenuOpen && <div className="App-gallery-content">
                <div className="App-gallery-content-inner">
                    {models.map((modelName, i) =>
                        <GalleryPicture key={i + ''} image={uiSettings[modelName].profileImage} text={uiSettings[modelName].name} width={300} />//width={210} />
                    )}
                </div>
            </div>}

        </div>
    );
}

// const styles = StyleSheet.create({
//     socialSection: {
//         position: 'absolute'
//     }
// })


// function chunk_<T>(array: T[], chunks: number) {
//     return array.reduce<T[][]>((resultArray, item, index) => {
//         const chunkIndex = Math.floor(index / chunks);
//         if (!resultArray[chunkIndex]) {
//             resultArray[chunkIndex] = [];
//         }
//         resultArray[chunkIndex].push(item);
//         return resultArray;
//     }, []);
// }
