import React, { FC, useState } from 'react';

interface Props {
    className?: string;
    dimensions: {
        name: string;
        size: {
            in: string;
            cm: string;
        }
    }[];
}

export const ModelDimensions: FC<Props> = ({ className, dimensions }) => {
    const [unit, setUnit] = useState<'cm' | 'in'>('cm');

    return (
        <div className={`App-ModelDimensions ${className || ''}`}>
            <div className="ModelDimensions-dimensions">
                {dimensions.map((dimension, index) =>
                    (<p key={index}>{dimension.name.toUpperCase()} {dimension.size[unit]}</p>))
                }
            </div>
            {/* <div className="ModelDimensions-units">
                <p>CM</p>
                <div className="ModelDimensions-units-underline"></div>
                <p>IN</p>
            </div> */}

        </div>
    );
}