import React, { FC, useEffect, useState } from 'react';

interface Props {
    className?: string;
    color?: 'mustard' | 'white';

}
export const SectionAcademy: FC<Props> = ({ className, color = 'white', children }) => {
    const [isMenuOpen, setMenuOpen] = useState(false);

    return (
        <div className={`section-container ${color}`}>
            {children}
        </div>
    );
}