
import React, { FC, useEffect, useState } from 'react';

interface Props {
    className?: string;
    onClick: () => void;
}

const sign = require("../../assets/academy/images/sign.png");

export const Schedule: FC<Props> = ({ onClick }) => {

    return (
        <div className={`schedule-container`}>
            <p style={{ fontWeight: 600, marginBottom: '80px' }}>SEMESTR LETNI</p>
            <p style={{ fontWeight: 500, marginBottom: '40px' }}>11-12.03.2023 (SOBOTA/NIEDZIELA)</p>
            <p style={{ fontWeight: 600, marginBottom: '30px' }}>KOLEJNE TERMINY JUŻ WKRÓTCE!</p>
            <p>*Warunkiem odbycia się zajęć jest utworzenie grupy min 10 uczestniczek</p>
            <img onClick={onClick} src={sign}></img>
        </div>

    );
}





