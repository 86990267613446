import React, { FC, useEffect, useRef, useState } from 'react';
import { StyleSheet } from 'style-sheet-component';
import "./imageInput.css"

interface Props {
    className?: string;
    onSelect: (p: any[]) => void;
}

export const ImageInput: FC<Props> = ({ className, onSelect }) => {
    const [value, setValue] = useState<any[]>([]);
    const [size, setSize] = useState<number>(0);

    const addPhoto = (e) => {
        e.preventDefault();
        // console.log(e.target.files[0])
        const file: File = e.target.files[0];
        if (!file) { return }

        const sizeCandidate = file.size / 1000000;
        if (size + sizeCandidate < 8) {
            setSize(size + sizeCandidate);
            const fr = new FileReader();
            fr.onload = () => setValue([...value, fr.result]);
            fr.readAsDataURL(e.target.files[0]);
        } else {
            alert('The size of photos exceeds 8MB. Please consider to upload lighter photos!');
        }
    }

    useEffect(() => {
        // console.log(value);
        onSelect(value);
    }, [value]);

    return (
        <>
            <p style={styles.additionalInfo}>Size of photos should not be greater than 8MB</p>

            <div style={styles.formInput} className={`App-ImageInput ${className || ''}`}>
                <input className="photo-file-input" onChange={addPhoto} name="file" max={1} multiple={true} style={styles.input} type="file"></input>
            </div>
            <div>
                {value && value.map((e, i) => <img key={i} style={{ marginRight: 10 }} height={200} src={e} />)}
            </div>
        </>
    );
}

const styles = StyleSheet.create({
    additionalInfo: {
        color: 'white',
        fontFamily: 'Bangla MN',
        fontSize: '10px',
        // width: '20vw',
        // marginBottom: '10px',
        // textAlign: 'justify'
    },
    description: {
        fontSize: '13px',
        color: 'white',
        fontFamily: 'Bangla MN',
        paddingLeft: '2px'
    },
    formInput: {
        marginBottom: '15px'
    },
    input: {
        padding: '5px',
        // backgroundColor: '#979797',
        border: '1px solid black',
        color: 'black',
        fontSize: '12px',
        width: '200px'
    },
    inputError: {
        color: '#CC4545',
        marginTop: '10px',
        fontFamily: 'Arial',
        // fontFamily: 'Bangla MN',
        fontSize: '12px',
        letterSpacing: '1px'
    }
})