import React, { FC, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useMediaQuery } from 'src/hooks/mediaQueryHook';
import { SizesScreen } from 'src/screens/SizesMenuScreen';
import { StyleSheet } from 'style-sheet-component';
import { ModelDimensions } from './ModelDimensionsComponent';

interface Props {
    className?: string;
    title: string;
    dimensions: { name: string, size: { cm: string, in: string } }[]
    onSizesClick: () => void;
}

export const BottomContainerDetails: FC<Props> = ({ className, children, dimensions, title, onSizesClick }) => {
    const matches = useMediaQuery({ min: 500 });
    // const [isSizesShowm, setShownSizes] = useState(false);

    const match = useRouteMatch();

    return (
        <>
            {/* { isSizesShowm && <SizesScreen onClose={() => setShownSizes(false)} dimensions={dimensions} />} */}
            { matches ? <>
                <ModelDimensions dimensions={dimensions}></ModelDimensions>
                <p className="App-ModelName noselect">{title}</p>
                {children}
            </>
                : <div style={styles.mobileContainer}>
                    {children}
                    <p className="App-ModelName noselect">{title}</p>
                    <p className="App-ModelSize noselect" onClick={onSizesClick}>sizes →</p>
                </div>}
        </>
    );
}

const styles = StyleSheet.create({
    mobileContainer: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'space-around'
    }
})

