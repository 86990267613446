import { motion } from 'framer-motion';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { BottomContainerDetails } from 'src/components/BottomContainerDetailsComponent';
import { PictureCounter } from 'src/components/PictureCounterComponent';
import { useMediaQuery } from 'src/hooks/mediaQueryHook';
import { StyleSheet } from 'style-sheet-component';
import { Carousel } from '../components/CarouselComponent';
import { Logo } from '../components/LogoComponent';
import { MainMenu } from '../components/MainMenuComponent';
import { MenuIconAnimated } from '../components/MenuIconAnimatedComponent';
import { ModelDimensions } from '../components/ModelDimensionsComponent';
import { SizesScreen } from './SizesMenuScreen';
import { uiSettings } from "../uiSettings";

interface Props {
    className?: string;
    type: "portfolio" | "digitals"
}
const variants = {
    open: { x: "-100vw" },
    closed: { x: 0 },
}
export const ModelDetailsScreen: FC<Props> = ({ className, type }) => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const match = useRouteMatch();
    const history = useHistory();
    const { params } = match as any;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false)
    const [galleryItems, setGalleryItems] = useState<any[]>([]);

    useEffect(() => {
        const getGalleryItems = async (modelName: string) => {
            // console.log(match, params);
            const images = Promise.all(Array.from({ length: uiSettings[params.model].carousel[type] }, (_, i) =>
                import(`../assets/images/${modelName}/${type}/${i + 1}.jpg`)));

            images.then(i => i.map(image => image.default))
                .then(setGalleryItems);
            // setGalleryItems(images.map(image => image.default))
        }
        try {
            if (params && params.model && uiSettings[params.model] && uiSettings[params.model].carousel[type]) getGalleryItems(params.model);
            throw new Error();
        } catch (err) {
            // console.log('not found')
            // history.push('/notfound');
        }
    }, []);

    useEffect(() => {
        // console.log('galleryItems', galleryItems);
        // console.log('currentIndex: ', currentIndex)
        if (galleryItems && galleryItems[currentIndex + 1]) {
            const img = new Image();
            img.src = galleryItems[currentIndex + 1];
        }

    }, [currentIndex])

    return (
        <motion.div
            animate={isOpen ? "open" : "closed"}
            variants={variants}

            transition={{ ease: 'anticipate', duration: 0.35 }}
            style={styles.container}>
            <div className={`App-ModelDetailsScreen ${className || ''}`}>
                <Logo to="/" size="small" color="black" className="App-Logo-position" />
                <MenuIconAnimated id="MenuIconAnimated" color={isMenuOpen ? 'white' : 'black'} onOpen={setMenuOpen} />
                <div className="App-ModelInfo">
                    <Carousel items={galleryItems} onChange={setCurrentIndex}></Carousel>
                    <BottomContainerDetails
                        onSizesClick={() => setIsOpen(true)}
                        dimensions={uiSettings[params.model]?.dimensions || []}
                        title={uiSettings[params.model].name.toUpperCase()}>
                        <PictureCounter currentIndex={currentIndex} picturesLength={galleryItems.length} style={styles.counterContainer}></PictureCounter>
                    </BottomContainerDetails>
                </div>
                <MainMenu
                    isVisible={isMenuOpen}
                    verticalItems={['WOMEN', 'COMMERCIAL', 'NEW FACES', 'ACADEMY']}
                    horizontalItems={['ABOUT', 'JOIN', 'CONTACT']}
                    onClick={console.log}
                />
            </div>
            {isOpen && <SizesScreen dimensions={uiSettings[params.model]?.dimensions || []} onClose={() => setIsOpen(false)}></SizesScreen>}
        </motion.div>
    );
}


const styles = StyleSheet.create({
    counterContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    container: {
        display: 'flex',
        backgroundColor: 'white',
        height: '100vh'
    }
});