import React, { FC } from 'react';
import './privacyPolicy.css';
interface Props {
    className?: string;
}

export const PrivacyPolicy: FC<Props> = ({ className }) => {
    return (
        <div className={`App-PrivacyPolicy ${className || ''}`}>
            <p>If you want to join us please fill in all the fields below.
It’s also necessary to attach a photos of you without make up. Try to make it in a day light.  Photos must be of .jpg format, no larger than 500kb. Please be advised that we will contact only with selected candidates. All best!</p>
            <p>
                {/* By posting content (“Content”) to the Website or by otherwise submitting Content to us, Registrants automatically grant, and represent and warrant that they have the right to grant to Gaga Models, a non-exclusive, perpetual, irrevocable, sub-licensable (through multiple tiers), assignable, fully paid, royalty free, worldwide license to use, copy, modify, adapt, publish, make, sell, create derivative works of or incorporate into other works such Content, derive revenue or other remuneration from, communicate to the public, distribute (through multiple tiers), perform or display such Content (in whole or in part) and/or to incorporate such Content in other works in any form, media, or technology now known or later developed, and to grant and authorize sublicenses of the foregoing through multiple tiers of sub-licensees, including the right to exercise the copyright, publicity, and any other rights in and to any of the materials contained in such Content for any purpose, including for purposes of advertising and publicity on the Website and elsewhere. Gaga Models shall not be limited in any way in its use, commercial or otherwise, of any such Content, and Registrants hereby waive any moral rights ior approval rights in or to, such Content as well as the right to receive acredit or payment of any royalty or fee whatsoever to the anyone associated with the Content, including without limitation the photographer/videographer, author, composer producer or the subject of such Content.” */}
                By clicking on "SEND" you agree to the processing of personal data for the needs of the casting process (in accordance with the Law on Personal Data Protection 29.08.1997. Dz. U. No. 133, pos. 883).
            </p>
        </div>
    );
}

