import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as ChevronRight } from '../assets/svgs/chevronRight.svg';
import { ReactComponent as ChevronLeft } from '../assets/svgs/chevronLeft.svg';
import { wrap } from "popmotion";
import { motion, AnimatePresence } from "framer-motion";

interface Props {
    className?: string;
    items: any[];
    onChange?: (index: number) => void;
}
const variants = {
    enter: (direction: number) => {
        return {
            x: direction > 0 ? 1200 : -1200,
            opacity: 0
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1
    },
    exit: (direction: number) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 1200 : -1200,
            opacity: 0
        };
    }
};
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
};


// const width = 400;

export const Carousel: FC<Props> = ({ className, items, onChange }) => {

    const [[page, direction], setPage] = useState([0, 0]);
    const imageIndex = wrap(0, items.length, page);
    const mediaMatch = window.matchMedia('(min-width: 500px)');
    const [matches, setMatches] = useState(mediaMatch.matches);
    const [width, setWidth] = useState('600px');

    useEffect(() => {
        const handler = (e: any) => setMatches(e.matches);
        mediaMatch.addListener(handler);
        return () => mediaMatch.removeListener(handler);
    }, []);

    useEffect(() => {
        matches ? setWidth('600px') : setWidth('90%');
    }, [matches]);

    useEffect(() => {
        onChange && onChange(imageIndex);
    }, [imageIndex])



    const paginate = (newDirection: number) => {
        setPage([page + newDirection, newDirection]);
    };


    return (
        <div className={`App-Carousel ${className || ''}`}>

            {matches && <div style={{ display: 'flex', justifyItems: 'center' }}>
                <ChevronLeft onClick={() => paginate(-1)} className="ChevronLeft" />
            </div>}

            <AnimatePresence initial={false} custom={direction}>
                <motion.img
                    key={page}
                    src={items[imageIndex]}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    style={{ zIndex: -1, height: '70vh', maxWidth: 630, objectFit: 'contain', paddingLeft: matches ? 0 : 100, paddingRight: matches ? 0 : 100 }}
                    height={width}
                    transition={{
                        x: { type: "spring", stiffness: 300, damping: 30 },
                        opacity: { duration: 0.8 }
                    }}
                    drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                    dragElastic={1}
                    onDragEnd={(e, { offset, velocity }) => {
                        const swipe = swipePower(offset.x, velocity.x);
                        if (swipe < -swipeConfidenceThreshold) {
                            paginate(1);
                        } else if (swipe > swipeConfidenceThreshold) {
                            paginate(-1);
                        }
                    }}
                />
            </AnimatePresence>

            { matches && <div style={{ display: 'flex', justifyItems: 'center' }}>
                <ChevronRight onClick={() => paginate(1)} className="ChevronRight" />
            </div>}

        </div>
    );
}

