import React, { FC, useEffect, useState } from 'react';
import { FormInput } from 'src/components/formInput/FormInputComponent';
import { ImageInput } from 'src/components/ImageInput/ImageInputComponent';
import { PrivacyPolicy } from 'src/components/PrivacyPolicy/PrrivacyPolicyComponen';
import { ButtonSendMail } from 'src/components/buttonSendMail/buttonSendMailComponent';
import { Logo } from 'src/components/LogoComponent';
import { MenuIconAnimated } from 'src/components/MenuIconAnimatedComponent';
import { MainMenu } from 'src/components/MainMenuComponent';
import "./join.scss";
import { LogoAnimated } from 'src/components/LogoAnimated/LogoAnimatedComponent';

interface Props { }

export const JoinScreen: FC<Props> = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isOpenOutside, setOpenOutside] = useState(false);
    const [photos, setPhotos] = useState<any[]>();
    const [form, setFormValue] = useState({});
    const [valid, setFormValid] = useState(false);
    const [checkValid, setCheckValid] = useState(false);

    const [loader, setLoader] = useState(false);

    const setMenuOpen_ = (open: boolean) => {
        if (open === false && isMenuOpen) {
            setOpenOutside(open);
        }
        setMenuOpen(open);
    }

    const setNewFormValue = (formValue: { [x: string]: string }) => {
        if (formValue[Object.keys(formValue)[0]] === '') {
            delete form[Object.keys(formValue)[0]];
            setFormValue({ ...form });
            return;
        }

        setFormValue({
            ...form,
            ...formValue
        });
    };

    useEffect(() => {
        if (Object.keys(form).length === Object.keys({
            name: '',
            email: '',
            city: '',
            date: '',
            instagram: '',
            height: "",
            dimensions: '',
            hair: '',
            eyes: '',
            phone: ''
        }).length) {
            setFormValid(true);
        } else {
            setFormValid(false);
        }

    }, [form])

    useEffect(() => {
        document.body.style.backgroundColor = 'black';
    }, []);

    return (
        <div className="join-screen" >
            {loader && <div className="loader">
                <LogoAnimated white></LogoAnimated>
                <p className="join-screen-loader-description">Sending request ...</p>
            </div>}
            <Logo to="/" size="small" color="white" className="App-Logo-position" ></Logo>
            <MenuIconAnimated id="MenuIconAnimated" color="white" setOpenOutside={isOpenOutside} onOpen={setMenuOpen_} />
            <MainMenu
                isVisible={isMenuOpen}
                verticalItems={['WOMEN', 'COMMERCIAL', 'NEW FACES', 'ACADEMY']}
                horizontalItems={['ABOUT', 'JOIN', 'CONTACT']}
                onClick={() => setMenuOpen(false)}
            />

            <div className="join-page-wrapper">
                <div>
                    <div >
                        <p className="join-title">JOIN US</p>
                    </div>
                    <div className="join-form-wrapper">
                        <div className="join-form-column">
                            <FormInput checkValid={checkValid} onSelect={setNewFormValue} name="name" description="Name and surname"></FormInput>
                            <FormInput checkValid={checkValid} onSelect={setNewFormValue} name="email" description="E-mail"></FormInput>
                            <FormInput checkValid={checkValid} onSelect={setNewFormValue} name="city" description="City"></FormInput>
                            <FormInput checkValid={checkValid} onSelect={setNewFormValue} name="date" description="Date of birth"></FormInput>
                            <FormInput checkValid={checkValid} onSelect={setNewFormValue} validate={false} name="instagram" description="Link to Instagram"></FormInput>

                        </div>
                        <div className="join-form-column">
                            <FormInput checkValid={checkValid} onSelect={setNewFormValue} name="height" description="Height"></FormInput>
                            <FormInput checkValid={checkValid} onSelect={setNewFormValue} name="dimensions" description="Bust / Waist / Hips"></FormInput>
                            <FormInput checkValid={checkValid} onSelect={setNewFormValue} name="hair" description="Hair colour"></FormInput>
                            <FormInput checkValid={checkValid} onSelect={setNewFormValue} name="eyes" description="Eyes colour"></FormInput>
                            <FormInput checkValid={checkValid} onSelect={setNewFormValue} name="phone" description="Phone number"></FormInput>

                        </div>
                        <div className="join-form-column">
                            <PrivacyPolicy></PrivacyPolicy>
                            <ButtonSendMail valid={valid} onClick={() => {
                                setCheckValid(true);
                                if (!valid) {
                                    return;
                                }

                                if (!photos || photos?.length === 0) {
                                    alert('Please attach some of your photos!');
                                    return;
                                }
                                if (loader) {
                                    return;
                                }

                                setLoader(true);
                                fetch('/send_mail', {
                                    body: JSON.stringify({ ...form, photos }),
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                }).then(res => res.json())
                                    .then(res => {
                                        if (res.mailSent) {
                                            alert('Thank you for sending the message');
                                        } else {
                                            alert('Sorry, there were problems with sending your message');
                                        }
                                    })
                                    .catch(err => {
                                        console.error(err);
                                        alert('Sorry, there were problems with sending your message');
                                    })
                                    .finally(() => {
                                        setLoader(false);
                                    });


                            }}></ButtonSendMail>
                        </div>
                    </div>
                    <div style={{ flexGrow: 3, marginLeft: 20 }}>
                        <ImageInput onSelect={setPhotos} />
                    </div>
                </div>
            </div>
        </div>
    );

}
