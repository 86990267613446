
import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as PaintMustartSvg } from "../../assets/academy/svgs/ciapka1.svg";
interface Props {
    className?: string;
}

const faq = require("../../assets/academy/images/faq.png");

export const Faq: FC<Props> = ({ }) => {
    return (
        <div className={`faq-container`}>
            <p style={{ fontWeight: 600 }}>FAQ</p>
            <p style={{ marginBottom: '50px' }}>(Najczęsciej zadawne pytania)</p>
            <div className='inner-cointaner'>
                <PaintMustartSvg style={{ position: 'absolute', zIndex: 0, width: 200, top: -70 }} />
                <p style={{ fontWeight: 600, marginBottom: '10px' }}>Jak mogę opłacić kurs?</p>
                <p style={{ marginBottom: '50px' }}>Dane do przelewu wysyłane są po weryfikacji zgłoszenia.</p>
            </div>
            <div className='inner-cointaner'>
                <PaintMustartSvg style={{ position: 'absolute', zIndex: 0, width: 200, top: -70 }} />
                <p style={{ fontWeight: 600, marginBottom: '10px' }}>Kiedy odbędzie się kolejny kurs?</p>
                <p style={{ marginBottom: '30px' }}>Kurs planowany jest na 11-12 marca 2023.</p>
            </div>
        </div>
    );
}





