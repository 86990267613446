import React, { FC, useEffect, useRef, useState } from 'react';
import { Logo } from 'src/components/LogoComponent';
import { MainMenu } from 'src/components/MainMenuComponent';
import { MenuIconAnimated } from 'src/components/MenuIconAnimatedComponent';
import './about.css';

interface Props { }

export const AboutScreen: FC<Props> = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isOpenOutside, setOpenOutside] = useState(false);
    const setMenuOpen_ = (open: boolean) => {
        if (open === false && isMenuOpen) {
            setOpenOutside(open);
        }
        setMenuOpen(open);
    }

    useEffect(() => {
        document.body.style.backgroundColor = 'white';
    }, []);

    return (
        <div className="page-about notouch" >
            <Logo to="/" size="small" color="black" className="App-Logo-position" ></Logo>
            <MenuIconAnimated id="MenuIconAnimated" color={isMenuOpen ? "white" : "black"} setOpenOutside={isOpenOutside} onOpen={setMenuOpen_} />
            <MainMenu
                isVisible={isMenuOpen}
                verticalItems={['WOMEN', 'COMMERCIAL', 'NEW FACES', 'ACADEMY']}
                horizontalItems={['ABOUT', 'JOIN', 'CONTACT']}
                onClick={() => setMenuOpen(false)}
            />
            <div className="about-page-wrapper">
                <div>
                    <p className="about-title" >ABOUT</p>
                    <div className="about-text-wrapper">
                        <p className="about-text">
                            Remodels Management is not an ordinary Modeling agency.
                            It is a company driven by passion and a desire to cultivate timeless beauty.
                            Most importantly, we are an agency with experience on the other side.
                            The owner of the agency is a former Model who has worked in the industry for over twelve years.
                            A passion for modelling and a great deal of empathy, developed the idea of the Revolution.
                            Thanks to communication and a stable view of the industry requirements, we have established a conscious agency that leads its models from A-Z,  <br />
                            while maintaining individuality and various development paths.
                            We represent not only high fashion and commercial models, but social media influencers as well.
                        </p>
                        <video className="about-video" playsInline={true} autoPlay={true} muted={true} loop={true} src={require('../../assets/movies/about.mp4')} />
                    </div>
                </div>
            </div>
        </div>
    );
}

