import React, { FC, useEffect, useState } from 'react';
import { AppLogoText } from '../components/AppLogoTextComponent';
import { MainMenu } from '../components/MainMenuComponent';
import { MenuIconAnimated } from '../components/MenuIconAnimatedComponent';
import { Logo } from '../components/LogoComponent';
interface Props { }

export const NotFoundScreen: FC<Props> = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isOpenOutside, setOpenOutside] = useState(false);
    const setMenuOpen_ = (open: boolean) => {
        if (open === false && isMenuOpen) {
            setOpenOutside(open);
        }
        setMenuOpen(open);
    }
    useEffect(() => {
        document.body.style.backgroundColor = 'black';
    }, [])
    return (
        <div className="not-found-screen" >
            <Logo to="/" size="small" color="white" className="App-Logo-position"></Logo>
            <MenuIconAnimated id="MenuIconAnimated" color="white" setOpenOutside={isOpenOutside} onOpen={setMenuOpen_} />
            <MainMenu
                isVisible={isMenuOpen}
                verticalItems={['WOMEN', 'COMMERCIAL', 'NEW FACES', 'ACADEMY']}
                horizontalItems={['ABOUT', 'JOIN', 'CONTACT']}
                onClick={() => setMenuOpen(false)}
            />
            {/* {!isMenuOpen && <div> */}
            <p>PAGE NOT FOUND</p>
            {/* </div>} */}
        </div>
    );
}